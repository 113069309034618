import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject, Subscription } from "rxjs";
import { InvoiceService } from "src/app/shared/services/invoice.service";
import * as moment from "moment";

interface ColumnItem {
  name: string;
}

@Component({
  selector: "invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.css"],
})
export class InvoicesComponent implements OnInit, OnDestroy {
  customers = [];
  invoices = [];
  searchValue = "";
  visible = true;
  displayData: any[] = [];
  showFilter: boolean;
  columns: ColumnItem[] = [
    { name: "Invoice No." },
    { name: "Serial No" },
    { name: "Invoiced On" },
    { name: "Invoiced To" },
    { name: "Invoiced By" },
    { name: "Amount" },
  ];
  filterForm: FormGroup;
  searchForm: FormGroup;
  isFiltered: boolean;
  loading: boolean;
  currentPage: number = 0;
  pageLength: number = 10; // Set default page length to 10
  totalRecords: number;
  totalPages: number;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  subscriptions: Subscription[] = [];
  organizations = [];

  constructor(
    private router: Router,
    private invoiceService: InvoiceService,
    private datePipe: DatePipe
  ) {
    const startOfMonth = moment().startOf("month").toDate();
    const endOfMonth = moment().endOf("month").toDate();

    this.filterForm = new FormGroup({
      invoice_date__gte: new FormControl(startOfMonth),
      invoice_date__lte: new FormControl(endOfMonth),
      invoiced_to__contact__phone_number__icontains: new FormControl(""),
      invoiced_to__traffic_file_number__icontains: new FormControl(""),
      invoiced_to__emirates_id__icontains: new FormControl(""),
      organization: new FormControl(""), // Add organization to the form
    });

    this.searchForm = new FormGroup({
      search: new FormControl(""),
    });

    this.subscriptions.push(
      this.searchForm.get("search")?.valueChanges.subscribe((value) => {
        this.onSearchChange(value);
      })
    );
  }

  ngOnInit(): void {
    this.getOrganizations();
    this.getInvoices(
      0,
      this.pageLength,
      this.getDirtyValues(this.filterForm),
      this.searchForm.get("search")?.value
    );
  }

  getOrganizations() {
    this.invoiceService.getOrganizations().subscribe(
      (data) => {
        this.organizations = data.results;
      },
      (error) => {
        console.error("Failed to fetch organizations", error);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      if (sub && !sub.closed) {
        sub.unsubscribe();
      }
    });
    this.dtTrigger.unsubscribe();
  }

  newInvoice() {
    this.router.navigate(["invoices/invoice"]);
  }

  getInvoices(
    start: number,
    length: number,
    filter: any = {},
    search: string = ""
  ): void {
    this.loading = true;
    const subscription = this.invoiceService
      .getInvoices(start, length, filter, search)
      .subscribe(
        (res) => {
          this.invoices = res.data;
          this.totalRecords = res.recordsFiltered;
          this.totalPages = Math.ceil(this.totalRecords / this.pageLength);
          this.loading = false;
          this.refreshTable();
        },
        (error) => {
          this.loading = false;
          console.error(error);
        }
      );
    this.subscriptions.push(subscription);
  }

  onClickFilter() {
    this.showFilter = true;
  }

  closeDrawer() {
    this.showFilter = false;
  }

  onSubmitfilterForm() {
    this.loading = true;
    const datelte = this.datePipe.transform(
      this.filterForm.get("invoice_date__lte")?.value,
      "yyyy-MM-dd"
    );
    this.filterForm.patchValue({
      invoice_date__lte: datelte,
    });
    const dategte = this.datePipe.transform(
      this.filterForm.get("invoice_date__gte")?.value,
      "yyyy-MM-dd"
    );
    this.filterForm.patchValue({
      invoice_date__gte: dategte,
    });
    this.isFiltered = true;
    this.currentPage = 0;
    this.getInvoices(
      this.currentPage,
      this.pageLength,
      this.getDirtyValues(this.filterForm),
      this.searchForm.get("search")?.value
    );
    this.closeDrawer();
  }

  resetFilter() {
    const startOfMonth = moment().startOf("month").toDate();
    const endOfMonth = moment().endOf("month").toDate();

    this.filterForm.reset({
      invoice_date__gte: startOfMonth,
      invoice_date__lte: endOfMonth,
      invoiced_to__contact__phone_number__icontains: "",
      invoiced_to__traffic_file_number__icontains: "",
      invoiced_to__emirates_id__icontains: "",
    });
    this.isFiltered = false;
    this.currentPage = 0;
    this.getInvoices(
      this.currentPage,
      this.pageLength,
      this.getDirtyValues(this.filterForm),
      this.searchForm.get("search")?.value
    );
    this.closeDrawer();
  }

  refreshTable() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  onPageChange(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.getInvoices(
        this.currentPage * this.pageLength,
        this.pageLength,
        this.getDirtyValues(this.filterForm),
        this.searchForm.get("search")?.value
      );
    }
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};
    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];
      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });
    return dirtyValues;
  }

  getTotal(particulars: any[]): number {
    return particulars.reduce(
      (previousValue, item) =>
        previousValue + (item?.cost + item?.profit) * item?.quantity,
      0
    );
  }

  onSearchChange(search: string) {
    this.currentPage = 0;
    this.getInvoices(
      this.currentPage,
      this.pageLength,
      this.getDirtyValues(this.filterForm),
      search
    );
  }

  onSelectInvoice(item: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["modal/showinvoice"], {
        queryParams: { invoiceId: item.id },
      })
    );
    window.open(url, "_blank");
  }

  getPageNumbers(): number[] {
    const maxPages = 5;
    let startPage = Math.max(0, this.currentPage - Math.floor(maxPages / 2));
    const endPage = Math.min(startPage + maxPages - 1, this.totalPages - 1);
    startPage = Math.max(0, endPage - maxPages + 1);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i + 1);
    }
    return pages;
  }

  goToPage(page: number) {
    this.onPageChange(page - 1);
  }

  goToFirstPage() {
    this.onPageChange(0);
  }

  goToLastPage() {
    this.onPageChange(this.totalPages - 1);
  }

  onPageLengthChange(newLength: number) {
    this.pageLength = newLength;
    this.currentPage = 0;
    this.getInvoices(
      this.currentPage,
      this.pageLength,
      this.getDirtyValues(this.filterForm),
      this.searchForm.get("search")?.value
    );
  }
}
