import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class SecureInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = new URL(req.url);

    // Check if the hostname is not localhost or 0.0.0.0
    if (url.hostname !== "localhost" && url.hostname !== "0.0.0.0") {
      const secureReq = req.clone({
        url: req.url.replace("http://", "https://"),
      });
      // Send the cloned, "secure" request to the next handler
      return next.handle(secureReq);
    }

    // If the hostname is localhost or 0.0.0.0, proceed with the original request
    return next.handle(req);
  }
}
