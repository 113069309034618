import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { EMPTY, Observable } from "rxjs";
import { catchError, expand, map, reduce } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { errorhandler } from "./errorhandler";
import { Profile } from "../interfaces/profile";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  error_handler;
  constructor(
    private http: HttpClient,
    private notification: NzNotificationService
  ) {
    this.error_handler = new errorhandler();
  }

  getProfiles() {
    return this.http.get(environment.baseurl + "rest/profiles").pipe(
      // we recursively call the GET requests until there is no 'next' url
      expand((apiResponse) =>
        apiResponse["next"]
          ? this.http.get(
              apiResponse["next"].includes("https")
                ? apiResponse["next"]
                : apiResponse["next"].replace("http://", "https://")
            )
          : EMPTY
      ),

      map((apiResponse) => apiResponse["results"]),
      // we reduce the data of all GET requests to a single array
      reduce((accData, data) => accData.concat(data), []),
      catchError(this.error_handler.handleError)
    );
  }

  searchProfilesByKeyword(searchKey: string): Observable<any[]> {
    if (!searchKey) {
      return EMPTY;
    }
    return this.http
      .get(`${environment.baseurl}rest/profiles?search=${searchKey}`)
      .pipe(
        expand((apiResponse) =>
          apiResponse["next"]
            ? this.http.get(
                apiResponse["next"].includes("https")
                  ? apiResponse["next"]
                  : apiResponse["next"].replace("http://", "https://")
              )
            : EMPTY
        ),
        map((apiResponse) => apiResponse["results"]),
        reduce((accData, data) => accData.concat(data), []),
        catchError(this.error_handler.handleError)
      );
  }

  createProfile(form_Value) {
    return this.http
      .post(environment.baseurl + "rest/profiles", form_Value)
      .pipe(catchError(this.error_handler.handleError));
  }

  deleteProfile() {}

  searchProfilesByKeywordAndRole(
    keyword: string,
    role: number
  ): Observable<Profile[]> {
    return this.http.get<Profile[]>(
      `/api/profiles?keyword=${keyword}&role=${role}`
    );
  }

  getProfileByid(id) {
    return this.http.get(environment.baseurl + "rest/profiles/" + id).pipe(
      catchError((err) => {
        throw this.notification.error("Error", JSON.stringify(err));
      })
    );
  }

  editProfile(profile: FormData, id) {
    console.log(profile);
    return this.http
      .put(environment.baseurl + "rest/profiles/" + id, profile)
      .pipe(
        catchError((err) => {
          console.log(err);
          throw this.notification.error("Error", JSON.stringify(err));
        })
      );
  }

  filterprofiles(filter) {
    const params = new HttpParams({ fromObject: filter });
    return this.http
      .get(environment.baseurl + "rest/profiles/", { params: filter })
      .pipe(
        // we recursively call the GET requests until there is no 'next' url
        expand((apiResponse) =>
          apiResponse["next"]
            ? apiResponse["next"].includes("https")
              ? apiResponse["next"]
              : apiResponse["next"].replace("http://", "https://")
            : EMPTY
        ),
        // we map the api response to the data we actually want to return
        map((apiResponse) => apiResponse["results"]),
        // we reduce the data of all GET requests to a single array
        reduce((accData, data) => accData.concat(data), [])
      )
      .pipe(
        catchError((err) => {
          throw this.notification.error("Error", JSON.stringify(err));
        })
      );
  }
  getEmployeeProfiles() {
    return this.http.get(environment.baseurl + "rest/profiles?role=1").pipe(
      // we recursively call the GET requests until there is no 'next' url
      expand((apiResponse) =>
        apiResponse["next"]
          ? this.http.get(
              apiResponse["next"].includes("https")
                ? apiResponse["next"]
                : apiResponse["next"].replace("http://", "https://")
            )
          : EMPTY
      ),

      map((apiResponse) => apiResponse["results"]),
      // we reduce the data of all GET requests to a single array
      reduce((accData, data) => accData.concat(data), []),
      catchError(this.error_handler.handleError)
    );
  }

  getStudentProfiles() {
    return this.http.get(environment.baseurl + "rest/profiles?role=0").pipe(
      // we recursively call the GET requests until there is no 'next' url
      expand((apiResponse) =>
        apiResponse["next"]
          ? apiResponse["next"].includes("https")
            ? apiResponse["next"]
            : apiResponse["next"].replace("http://", "https://")
          : EMPTY
      ),

      map((apiResponse) => apiResponse["results"]),
      // we reduce the data of all GET requests to a single array
      reduce((accData, data) => accData.concat(data), []),
      catchError(this.error_handler.handleError)
    );
  }

  getStaffProfile() {
    return this.http.get(environment.baseurl + "rest/profiles?role=2").pipe(
      // we recursively call the GET requests until there is no 'next' url
      expand((apiResponse) =>
        apiResponse["next"]
          ? this.http.get(
              apiResponse["next"].includes("https")
                ? apiResponse["next"]
                : apiResponse["next"].replace("http://", "https://")
            )
          : EMPTY
      ),

      map((apiResponse) => apiResponse["results"]),
      // we reduce the data of all GET requests to a single array
      reduce((accData, data) => accData.concat(data), []),
      catchError(this.error_handler.handleError)
    );
  }

  getProfilesbyRole(
    role: number,
    start: number,
    length: number,
    filter: any = {},
    search: string = ""
  ) {
    let queryParams = `role=${role}&start=${start}&length=${length}&ordering=-created_at`;
    if (search) {
      queryParams += `&search=${search}`;
    }
    Object.keys(filter).forEach((key) => {
      queryParams += `&${key}=${filter[key]}`;
    });
    return this.http
      .get(
        `${environment.baseurl}rest/profiles?format=datatables&draw=1&${queryParams}`
      )
      .pipe(
        map((apiResponse: any) => ({
          data: apiResponse["data"],
          recordsTotal: apiResponse["recordsTotal"],
          recordsFiltered: apiResponse["recordsFiltered"],
        })),
        catchError((error) => {
          console.error("Error fetching profiles:", error);
          return EMPTY;
        })
      );
  }

  filterProfilesbylastname(filter) {
    return this.http.get(environment.baseurl + "rest/profiles/", {
      params: filter,
    });
  }

  getStudentProfilesbydate(date) {
    return this.http
      .get(environment.baseurl + "rest/profiles?role=0", { params: date })
      .pipe(
        // we recursively call the GET requests until there is no 'next' url
        expand((apiResponse) =>
          apiResponse["next"]
            ? apiResponse["next"].includes("https")
              ? apiResponse["next"]
              : apiResponse["next"].replace("http://", "https://")
            : EMPTY
        ),

        map((apiResponse) => apiResponse["results"]),
        // we reduce the data of all GET requests to a single array
        reduce((accData, data) => accData.concat(data), []),
        catchError(this.error_handler.handleError)
      );
  }
}
