import { style } from "@angular/animations";
import { DatePipe } from "@angular/common";
import { ArrayType } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Observable, Subject } from "rxjs";
import { GlobalService } from "src/app/shared/services/global.service";
import { InvoiceService } from "src/app/shared/services/invoice.service";
import { ProductsService } from "src/app/shared/services/products.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { Invoice } from "../invoice";
import { Profile } from "src/app/shared/interfaces/profile";
import { debounceTime, distinctUntilChanged, finalize } from "rxjs/operators";

@Component({
  selector: "invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.css"],
})
export class InvoiceComponent implements OnInit {
  customers$: Observable<Profile[]>;
  profiles = [];
  trainers = [];
  organizations = [];

  filterdproducts: any[];
  selectedService: any;
  selectedIndex: any;
  datePipe: any;
  invoice: any;
  addMOde: boolean;
  addMode: boolean;
  editMode: boolean;
  saved: boolean = false;
  savedInvoice;
  globals: any;
  nzFilterOption = (): boolean => true;
  loading: boolean = false;

  private searchSubject = new Subject<string>();
  private searchTrainersSubject = new Subject<string>();

  compareFun = (o1: Profile | string, o2: Profile): boolean => {
    if (o1) {
      return typeof o1 === "string"
        ? o1 === o2.contact?.first_name
        : o1.id === o2.id;
    } else {
      return false;
    }
  };

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private ProductsService: ProductsService,
    private invoiceService: InvoiceService,
    private datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private global: GlobalService
  ) {}

  products = [];
  filteredOptions = [];
  particularForm = this.fb.group({
    cost: [""],
    quantity: [""],
    description: [
      "0",
      Validators.compose([
        Validators.required,
        Validators.minLength(0),
        Validators.maxLength(10),
      ]),
    ],
    title: [""],
    amount: [""],
  });
  invoiceForm = this.fb.group({
    organization: ["", Validators.required],
    invoiced_to: ["", Validators.required],
    referred_by: [""],
    invoice_date: ["", Validators.required],
    description: [""],
    particulars: this.fb.array([]),
  });

  inputValue = "";
  dateFormat = "YYYY-MM-dd";
  invoice_date;

  subTotal = 0;

  ngOnInit(): void {
    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.performSearch(searchTerm);
      });

    this.searchTrainersSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.performSearchTrainers(searchTerm);
      });
    this.loadOrganizations();

    this.invoice = history.state.data?.item;
    this.invoiceForm.patchValue({
      invoice_date: this.datepipe.transform(new Date(), "YYYY-MM-dd"),
    });
    if (this.invoice == null) {
      this.addMode = true;
      this.customers$ = this.profileService.getProfiles();
      this.getServices();
      this.getglobals();
    } else {
      this.editMode = true;
      this.invoiceForm.patchValue(this.invoice);
    }
  }

  performSearch(searchTerm: string) {
    const filter = { search: searchTerm };

    this.profileService.filterProfilesbylastname(filter).subscribe((res) => {
      const profiles = [];
      res["results"].forEach((item) => {
        profiles.unshift(item);
      });
      this.profiles = profiles;
    });
  }

  performSearchTrainers(searchTerm: string) {
    const filter = { search: searchTerm, role: 1 };

    this.profileService.filterProfilesbylastname(filter).subscribe((res) => {
      const trainers = [];
      res["results"].forEach((item) => {
        trainers.unshift(item);
      });
      this.trainers = trainers;
    });
  }

  get particulars() {
    return this.invoiceForm.get("particulars") as FormArray;
  }

  addRow() {
    this.particularForm = this.fb.group({
      description: ["0"],
      cost: [""],
      quantity: [1],
      title: [""],
      amount: [""],
    });
    this.particulars.push(this.particularForm);
  }

  submitForm() {}

  saveInvoice() {
    this.createInovice();
  }

  removeField(lessonIndex: number) {
    this.particulars.removeAt(lessonIndex);
    this.getTotal();
  }

  getTotal() {
    this.subTotal = this.particulars.value.reduce(
      (prev, next) => prev + next.amount,
      0
    );
  }

  getServices() {
    this.ProductsService.getProducts().subscribe((res) => {
      this.products = res["results"];
    });
  }

  onChange($event, i) {
    this.filterdproducts = [...this.products].filter(
      (item) =>
        item?.title?.toLowerCase().indexOf($event?.toString().toLowerCase()) !==
        -1
    );
    this.selectedService = this.products.find((item) => {
      return (item.id = $event);
    });
  }

  onChangeprofile($event) {
    const filter = {
      contact__last_name__icontains: $event,
    };

    this.profileService.filterProfilesbylastname(filter).subscribe((res) => {
      // Handle filtered profiles
    });
  }

  onSelect(e, i) {
    const value = (e.target as HTMLInputElement).value;
    this.selectedIndex = i;
    const selected = this.products.find((item) => {
      return item.title == value;
    });
    this.particulars
      .at(i)
      .get("cost")
      .patchValue(
        selected.price.cost + selected.price.profit + selected.price.tax
      );
    this.particulars.at(i).get("title").patchValue(value);
    this.calculate();
  }

  calculate() {
    const cost = this.particulars.at(this.selectedIndex).get("cost").value;
    const qty = this.particulars.at(this.selectedIndex).get("quantity").value;
    const amount = cost * qty;
    this.particulars.at(this.selectedIndex).get("amount").patchValue(amount);
    this.getTotal();
  }

  createInovice() {
    const date = this.invoiceForm.get("invoice_date").value;
    const cdate = this.datepipe.transform(date, "YYYY-MM-dd");
    this.invoiceForm.patchValue({
      invoice_date: cdate,
    });
    const formvalue = this.invoiceForm.value;
    if (this.invoiceForm.valid) {
      this.loading = true;
      this.invoiceService
        .createInvoice(formvalue)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          (res) => {
            this.savedInvoice = res;
            this.notification.success("Success", "Invoice Saved");
            this.saved = true;
          },
          (error) => {
            this.notification.error("Error", error);
          }
        );
    } else {
      this.notification.error("oops", "Please fill all required fields");
    }
  }

  loadOrganizations() {
    this.invoiceService.getOrganizations().subscribe((res) => {
      this.organizations = res.results;
      if (this.organizations.length > 0) {
        this.invoiceForm.get("organization").setValue(this.organizations[0].id);
      }
    });
  }

  onOrganizationChange(organizationId: number) {
    const currentOrganizationId = this.invoiceForm.get("organization").value;
    if (currentOrganizationId !== organizationId) {
      this.invoiceForm.patchValue({ organization: organizationId });
    }
  }

  // onPrint() {
  //   const item = this.savedInvoice;
  //   this.router.navigate(["pages/invoices/viewinvoice"], {
  //     state: { data: { item }, invoiceId: item.id },
  //   });
  // }

  onPrint() {
    const item = this.savedInvoice;
    console.log(item);
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["modal/showinvoice"], {
        queryParams: { invoiceId: item.id },
      })
    );
    window.open(url, "_blank");
  }

  getglobals() {
    this.global.getGlobals().subscribe((res) => {
      this.globals = res;
    });
  }

  addcoustomer() {
    this.router.navigate(["/pages/profile"]);
  }

  search($event) {
    this.searchSubject.next($event);
  }

  searchTrainers($event) {
    this.searchTrainersSubject.next($event);
  }
}
