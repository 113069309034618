import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { errorhandler } from "./errorhandler";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  url = environment.baseurl + "rest/services/";
  headers = new HttpHeaders().set(
    "Authorization",
    "Token " + localStorage.getItem("Token")
  );

  public _errorhandler: errorhandler;
  constructor(
    private http: HttpClient,
    private notification: NzNotificationService
  ) {
    this._errorhandler = this._errorhandler;
  }

  getProducts() {
    return this.http.get(this.url).pipe(
      catchError((err) => {
        throw this.notification.error("Error", JSON.stringify(err));
      })
    );
  }

  addProducts(products) {
    return this.http.post(this.url, products).pipe(
      catchError((err) => {
        throw this.notification.error("Error", JSON.stringify(err));
      })
    );
  }

  deleteProduct(id) {
    return this.http.delete(this.url + id).pipe(
      catchError((err) => {
        throw this.notification.error("Error", JSON.stringify(err));
      })
    );
  }
  editProducts(id, item: FormData) {
    console.log(id);
    return this.http
      .put(environment.baseurl + "rest/services/" + id, item)
      .pipe(
        catchError((err) => {
          throw this.notification.error("Error", JSON.stringify(err));
        })
      );
  }
}
