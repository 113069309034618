<div [hidden]="loading">
  <nz-card>
    <div class="row m-b-30" style="margin-left: auto; margin-right: 0">
      <!-- <div class="col-lg-8">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Services" [(ngModel)]="searchInput" (ngModelChange)="search()">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status">
                        <nz-option nzLabel="All" nzValue="all"></nz-option>
                        <nz-option nzLabel="Approved" nzValue="approved"></nz-option>
                        <nz-option nzLabel="Pending" nzValue="pending"></nz-option>
                        <nz-option nzLabel="Rejected" nzValue="rejected"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div> -->
      <div class="col-lg-4">
        <button nz-button nzType="primary" (click)="addProduct()">
          <i nz-icon nzType="plus" theme="outline"></i>
          <span>Add</span>
        </button>
      </div>
    </div>
    <div class="ant-table-wrapper ng-star-inserted hscroll">
      <Table
        nz-table
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        class="table ant-table"
      >
        <thead>
          <tr>
            <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">
              {{ column.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of services; let i = index"
            class="ant-table-cell-content"
          >
            <td>#{{ item.id }}</td>
            <td>{{ item.service_code }}</td>
            <td>
              <div class="d-flex align-items-center">
                <nz-avatar
                  [nzSize]="30"
                  nzIcon="car"
                  [nzSrc]="item.avatar"
                ></nz-avatar>
                <h6 class="m-l-10 m-b-0">{{ item.title }}</h6>
              </div>
            </td>
            <td>{{ item.created_at | date : "dd-MM-yyyy hh:mm a" }}</td>
            <td *ngIf="item.price">
              {{
                item.price.cost + item.price.profit + item.price.tax
                  | number : "3.2-5"
              }}
              <small>AED</small>
            </td>
            <td *ngIf="!item.price">0</td>

            <td class="text-md-right">
              <a
                class="m-r-5"
                nz-button
                nzType="default"
                nzShape="circle"
                nz-tooltip
                nzTooltipTitle="Edit"
              >
                <i
                  nz-icon
                  nzType="edit"
                  theme="outline"
                  (click)="onClickeditItem(item, i)"
                ></i>
              </a>
              <button
                nz-button
                nzType="default"
                nzShape="circle"
                nz-tooltip
                nzTooltipTitle="Delete"
              >
                <i
                  nz-icon
                  nzType="delete"
                  theme="outline"
                  nz-popconfirm
                  nzPopconfirmTitle="Are you sure?"
                  nzOkText="ok"
                  nzCancelText="cancel"
                  (nzOnConfirm)="confirm(item?.id)"
                  (nzOnCancel)="cancel()"
                ></i>
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>

    <nz-drawer
      [nzWidth]="720"
      [nzClosable]="false"
      [nzVisible]="visible"
      [nzPlacement]="placement"
      nzTitle="{{ tittle }}"
      (nzOnClose)="closeDrawer()"
    >
      <ng-container *nzDrawerContent>
        <form [formGroup]="serviceForm" nz-form nzLayout="vertical">
          <div nz-row>
            <div nz-col-12 class="mr-4">
              <nz-form-item [formGroup]="serviceForm">
                <nz-form-label nzFor="Service Name">Title</nz-form-label>
                <nz-form-control nzErrorTip="Please input this field!">
                  <input nz-input formControlName="title" type="text" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col-12 class="ml-4">
              <nz-form-item>
                <nz-form-label nzFor="service Code">Service code</nz-form-label>
                <nz-form-control nzErrorTip="Please input this field!">
                  <input nz-input formControlName="service_code" type="text" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div formGroupName="price">
            <div nz-row>
              <div nz-col nzSpan="8" class="mr-3">
                <nz-form-item>
                  <nz-form-label nzFor="cost">Cost</nz-form-label>
                  <nz-form-control nzErrorTip="Please input this field!">
                    <nz-input-group [nzPrefix]="pricePrefix">
                      <input nz-input formControlName="cost" type="text" />
                    </nz-input-group>
                    <ng-template #pricePrefix>
                      <span>AED</span>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <nz-form-item>
                           <nz-form-label nzFor="category">Category</nz-form-label>
                          <nz-form-control nzErrorTip="Please input this field!">
                              <nz-select formControlName="category" class="w-100">
                        <nz-option nzLabel="Cloths" nzValue="Cloths"></nz-option>
                        <nz-option nzLabel="Home Decoration" nzValue="Home Decoration"></nz-option>
                        <nz-option nzLabel="Eletronic" nzValue="Eletronic"></nz-option>
                        <nz-option nzLabel="Jewellery" nzValue="Jewellery"></nz-option>
                         </nz-select>
                           </nz-form-control>
                          </nz-form-item> -->
              <div nz-col nzSpan="8" class="ml-3">
                <nz-form-item>
                  <nz-form-label nzFor="profit">Profit</nz-form-label>
                  <nz-form-control nzErrorTip="Please input this field!">
                    <input nz-input formControlName="profit" type="text" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <nz-form-item nz-col nzSpan="8">
                <nz-form-label nzFor="tax">Tax</nz-form-label>
                <nz-form-control nzErrorTip="Please input this field!">
                  <input nz-input formControlName="tax" type="text" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <!-- <nz-form-item>
                    <nz-form-label nzFor="status">Status</nz-form-label>
                    <nz-form-control nzErrorTip="Please input this field!">
                        <nz-select formControlName="status" class="w-100">
                            <nz-option nzLabel="In Stock" nzValue="In Stock"></nz-option>
                            <nz-option nzLabel="Out of Stock" nzValue="Out of Stock"></nz-option>
                            <nz-option nzLabel="Pending" nzValue="Pending"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item> -->

          <div nz-row>
            <div class="mr-3">
              <nz-form-item>
                <button
                  type="submit"
                  nz-button
                  nzType="primary"
                  *ngIf="!editMode"
                  (click)="onSubmit()"
                >
                  Create
                </button>
                <button
                  type="submit"
                  nz-button
                  nzType="primary"
                  *ngIf="editMode"
                  (click)="editItem()"
                >
                  Save
                </button>
              </nz-form-item>
            </div>

            <nz-form-item>
              <button (click)="closeDrawer()" nz-button nzType="default">
                Cancel
              </button>
            </nz-form-item>
          </div>
        </form>
      </ng-container>
    </nz-drawer>
  </nz-card>
</div>
<div
  *ngIf="loading"
  class="d-flex flex-column min-vh-100 justify-content-center align-items-center"
  style="height: 600px; margin: top 1600px"
>
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>
