import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { UserprofileService } from 'src/app/shared/services/userprofile.service';

@Component({
  selector: 'trainers',
  templateUrl:'./trainers.component.html',
  styleUrls: ['./trainers.component.css']
})

export class TrainersComponent implements OnInit {

  view: string = 'cardView'
  role=1;
  title="Employees";
  employees=[];
  memberList = [
    {
        name: 'Erin Gonzales',
        img: 'assets/images/avatars/thumb-1.jpg',
        mail: 'erin.gon@gmail.com'
    },
    {
        name: 'Darryl Day',
        img: 'assets/images/avatars/thumb-2.jpg',
        mail: 'darryl.d@gmail.com'
    },
    {
        name: 'Marshall Nichols',
        img: 'assets/images/avatars/thumb-3.jpg',
        mail: 'marshalln@gmail.com'
    },
    {
        name: 'Virgil Gonzales',
        img: 'assets/images/avatars/thumb-4.jpg',
        mail: 'virgil14@gmail.com'
    },
    {
        name: 'Nicole Wyne',
        img: 'assets/images/avatars/thumb-5.jpg',
        mail: 'nicolew@gmail.com'
    },
    {
        name: 'Riley Newman',
        img: 'assets/images/avatars/thumb-6.jpg',
        mail: 'rileyn93@gmail.com'
    },
    {
        name: 'Pamela Wanda',
        img: 'assets/images/avatars/thumb-7.jpg',
        mail: 'pamelaw@gmail.com'
    }
]

constructor( private profileService:ProfileService,
  private router:Router) { }

ngOnInit(): void {
  //this.getEmployees();
}

// getEmployeees(){
//   this.profileService.getProfiles().subscribe(res=>{
//      console.log(res);
//      let employees=res['results'];
//      this.employees=employees?.filter(item=>{
//       return item.role==1;
//      })
//     console.log(this.employees);
//   })
// }


getEmployees(){
  this.profileService.getEmployeeProfiles().subscribe(res=>{
    console.log(res);
    this.employees=res['results'];
})
}


addProfile(){
  this.router.navigate(['dashboard/pages/profile']);
}
}
