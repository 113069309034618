import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "transformProfileImageUrl",
})
export class TransformProfileImageUrlPipe implements PipeTransform {
  transform(profileImage: string | null): string | null {
    if (!profileImage) {
      return null;
    }

    const match = profileImage.match(/image\/upload\/(https?:\/\/[^\s]+)/);
    if (match && match[1]) {
      const originalUrl = match[1];
      const transformedUrl = originalUrl.replace(/upload\//, "upload/w_200/");
      return transformedUrl;
    }
    return profileImage; // Return the original string if it doesn't match the expected pattern
  }
}
