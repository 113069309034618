<div>
  <nz-card>
    <div class="row m-b-30 text-right">
      <div class="m-b-10 m-l-10 d-flex align-items-right">
        <button nz-button nzType="primary" (click)="onClickFilter()">
          <i nz-icon nzType="filter" theme="outline"></i>
          <span>Filter</span>
        </button>
      </div>
      <div class="m-b-10 m-l-10 d-flex align-items-right">
        <button nz-button nzType="primary" [routerLink]="['/invoices/invoice']">
          <i nz-icon nzType="plus" theme="outline"></i>
          <span>Add Invoice</span>
        </button>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12 d-flex align-items-center justify-content-end">
        <label for="itemsPerPage" class="mr-2">Items per page:</label>
        <nz-select
          id="itemsPerPage"
          [(ngModel)]="pageLength"
          (ngModelChange)="onPageLengthChange($event)"
        >
          <nz-option
            *ngFor="let size of [10, 20, 30, 40, 50]"
            [nzValue]="size"
            [nzLabel]="size"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-12">
        <form [formGroup]="searchForm">
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input nz-input placeholder="Search" formControlName="search" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </form>
      </div>
    </div>

    <div class="ant-table-wrapper ng-star-inserted hscroll">
      <div
        *ngIf="loading"
        class="d-flex justify-content-center align-items-center"
      >
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
      </div>
      <table
        *ngIf="!loading"
        nz-table
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        class="table ant-table"
      >
        <thead>
          <tr>
            <th *ngFor="let column of columns">
              {{ column.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of invoices" (click)="onSelectInvoice(item)">
            <td>{{ item.invoice_no }}</td>
            <td>{{ item.invoice_number }}</td>
            <td>{{ item.invoice_date | date : "dd-MM-yyyy" }}</td>
            <td>
              {{ item.invoiced_to?.contact?.first_name | titlecase }}
              {{ item.invoiced_to?.contact?.last_name | titlecase }}<br />
              <small>
                {{
                  item.invoiced_to?.contact?.phone_number | phoneNumber : "AE"
                }}
              </small>
            </td>

            <td>
              {{ item.invoiced_by?.account?.contact?.first_name | titlecase }}
              {{ item.invoiced_by?.account?.contact?.last_name | titlecase }}
            </td>
            <td>{{ getTotal(item.particulars) }}</td>
          </tr>
        </tbody>
      </table>
      <!-- Pagination Controls -->
      <div
        class="pagination-controls"
        style="float: right; display: flex; align-items: center"
      >
        <button
          nz-button
          (click)="goToFirstPage()"
          [disabled]="currentPage === 0"
        >
          First
        </button>
        <button
          nz-button
          (click)="onPageChange(currentPage - 1)"
          [disabled]="currentPage === 0"
        >
          Previous
        </button>
        <ng-container *ngFor="let page of getPageNumbers()">
          <button
            nz-button
            (click)="goToPage(page)"
            [nzType]="page === currentPage + 1 ? 'primary' : 'default'"
          >
            {{ page }}
          </button>
        </ng-container>
        <button
          nz-button
          (click)="onPageChange(currentPage + 1)"
          [disabled]="currentPage + 1 >= totalPages"
        >
          Next
        </button>
        <button
          nz-button
          (click)="goToLastPage()"
          [disabled]="currentPage + 1 >= totalPages"
        >
          Last
        </button>
        <span style="padding-left: 10px">
          Page {{ currentPage + 1 }} of {{ totalPages }}
        </span>
      </div>
    </div>
  </nz-card>
</div>

<nz-drawer
  [nzClosable]="true"
  [nzVisible]="showFilter"
  nzPlacement="right"
  nzTitle="Filter Invoices"
  (nzOnClose)="closeDrawer()"
  [nzWidth]="400"
>
  <ng-container *nzDrawerContent class="p-20">
    <form
      [formGroup]="filterForm"
      (ngSubmit)="onSubmitfilterForm()"
      nz-form
      nzLayout="vertical"
    >
      <div nz-row>
        <div nz-col-12 class="mr-4">
          <nz-form-item>
            <nz-form-label nzFor="service Name">Phone Number</nz-form-label>
            <nz-form-control nzErrorTip="Please input this field!">
              <input
                nz-input
                formControlName="invoiced_to__contact__phone_number__icontains"
                type="text"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <div nz-col-12 class="mr-4">
          <nz-form-item>
            <nz-form-label nzFor="organization">Organization</nz-form-label>
            <nz-form-control nzErrorTip="Please select an organization!">
              <nz-select
                formControlName="organization"
                nzPlaceHolder="Select organization"
              >
                <nz-option
                  *ngFor="let org of organizations"
                  [nzValue]="org.id"
                  [nzLabel]="org.name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-row>
        <div nz-col-12 class="mr-4">
          <nz-form-item>
            <nz-form-label nzFor="service Name">TCN</nz-form-label>
            <nz-form-control nzErrorTip="Please input this field!">
              <input
                nz-input
                formControlName="invoiced_to__traffic_file_number__icontains"
                type="text"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col-12 class="mr-4">
          <nz-form-item>
            <nz-form-label nzFor="service Name">Emirates ID</nz-form-label>
            <nz-form-control nzErrorTip="Please input this field!">
              <input
                nz-input
                formControlName="invoiced_to__emirates_id__icontains"
                type="text"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div nz-col-12 class="mr-4">
          <nz-form-item>
            <nz-form-label nzFor="service Name">From Date</nz-form-label>
            <nz-form-control nzErrorTip="Please input this field!">
              <nz-date-picker
                formControlName="invoice_date__gte"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col-12 class="ml-8">
          <nz-form-item>
            <nz-form-label nzFor="service Name">To Date</nz-form-label>
            <nz-form-control nzErrorTip="Please input this field!">
              <nz-date-picker
                formControlName="invoice_date__lte"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div class="mr-3">
          <nz-form-item>
            <button type="submit" nz-button nzType="primary">Filter</button>
          </nz-form-item>
        </div>
        <div class="mr-3">
          <nz-form-item>
            <button
              type="button"
              nz-button
              nzType="primary"
              *ngIf="isFiltered"
              (click)="resetFilter()"
            >
              Reset
            </button>
          </nz-form-item>
        </div>
        <nz-form-item>
          <button (click)="closeDrawer()" nz-button nzType="default">
            Cancel
          </button>
        </nz-form-item>
      </div>
    </form>
  </ng-container>
</nz-drawer>
