import { Component } from "@angular/core";
import { Routes } from "@angular/router";
import { AuthGuard } from "src/app/authentication/authguard";
import { EmployeesComponent } from "src/app/employees/employees.component";
import { InvoiceComponent } from "src/app/pages/invoices/invoice/invoice.component";
import { InvoicesComponent } from "src/app/pages/invoices/invoices.component";
import { ServicesComponent } from "src/app/pages/services/services.component";
import { TrainersComponent } from "src/app/pages/trainers/trainers.component";
import { TrainingsessionsComponent } from "src/app/pages/trainingsessions/trainingsessions.component";

export const CommonLayout_ROUTES: Routes = [
  // {
  //    path:'',
  //    canActivate:

  // },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "invoices",
    component: InvoicesComponent,
    data: {
      title: "Invoices",
    },

    //loadChildren: () => import('../../pages/pagesmoudule/pagesmoudule.module').then(m => m.PagesmouduleModule),
  },
  {
    path: "trainers",
    component: TrainersComponent,
    data: {
      title: "Trainers",
    },
    //loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: "employees",
    component: EmployeesComponent,
    data: {
      title: "Employees",
    },
    //loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: "invoices/invoice",
    component: InvoiceComponent,
    data: {
      title: "Invoice",
    },

    //loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: "services",
    component: ServicesComponent,
    data: {
      title: "Services",
    },

    //loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: "trainingsessions",
    component: TrainingsessionsComponent,
    data: {
      title: "Training Sessions",
    },

    //loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: "pages",
    data: {
      title: "Pages",
    },
    children: [
      // {
      //     path: '',
      //     redirectTo: '/dashboard/home',
      //     pathMatch: 'full'
      // },
      {
        path: "",
        loadChildren: () =>
          import("../../pages/pagesmodule/pagesmoudule.module").then(
            (m) => m.PagesmouduleModule
          ),
      },
    ],
    //loadChildren: () => import('../../pages/pagesmoudule/pagesmoudule.module').then(m => m.PagesmouduleModule),
  },
];
