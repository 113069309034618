import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { User } from "../interfaces/user.type";
import { environment } from "src/environments/environment";

import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd/notification";

const USER_AUTH_API_URL = environment.baseurl + "auth/login/";
const LOG_OUT_URL = environment.baseurl + "/auth/logout/";

@Injectable()
export class AuthenticationService {
  isloggedin: boolean;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private notification: NzNotificationService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // login(username: string, password: string) {
  //   console.log('inlogin');
  //     return this.http.post<any>(USER_AUTH_API_URL, { username, password })
  //     .pipe(map(user => {
  //         if (user && user.token) {
  //             localStorage.setItem('currentUser', JSON.stringify(user));
  //             this.currentUserSubject.next(user);
  //         }
  //         return user;
  //     }));
  //     // if(username&&password == 'admin'){
  //     //   localStorage.setItem('isloggedin','true')
  //     // }
  // }

  login(username, password) {
    const headers = new HttpHeaders().set(
      "Authorization",
      "Basic " + btoa(username + ":" + password)
    );

    this.http
      .post(USER_AUTH_API_URL, "", { headers })
      .pipe(
        catchError((err) => {
          this.notification.error("Erorr", "There was an error getting data");
          localStorage.removeItem("Token");
          this.router.navigate(["authentication/login"]);
          return throwError(err);
        })
      )
      .subscribe((res) => {
        if (res["token"] != null) {
          this.router.navigate(["/dashboard/home"]);
          localStorage.setItem("Token", res["token"]);
          localStorage.setItem("user", JSON.stringify(res));
          this.notification.success("Success", "Logged in successfully");
        }
      });
  }

  logoutserver() {
    this.router.navigate(["authentication/login"]);
    localStorage.clear();
    return this.http.post(environment.baseurl + "auth/logout/", {});
  }

  logout() {
    this.logoutserver().subscribe((res) => {
      localStorage.removeItem("Token");
      localStorage.removeItem("user");
      this.currentUserSubject.next(null);
      this.router.navigate(["authentication/login"]);
    });
  }

  getAuthStatus() {
    if (localStorage.getItem("Token") !== null) return true;
  }
}
