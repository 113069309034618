<div class="header">
  <div class="logo logo-dark">
    <a href="">
      <img src="assets/images/logo/pdslogo.png" alt="Logo" />
      <img class="logo-fold" src="assets/images/logo/pdssmall.png" alt="Logo" />
    </a>
  </div>
  <div class="logo logo-white">
    <a href="">
      <img src="assets/images/logo/pdslogo.png" alt="Logo" />
      <img class="logo-fold" src="assets/images/logo/pdssmall.png" alt="Logo" />
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i
            nz-icon
            [nzType]="isFolded ? 'menu-unfold' : 'menu-fold'"
            theme="outline"
          ></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i
            nz-icon
            [nzType]="isExpand ? 'menu-fold' : 'menu-unfold'"
            theme="outline"
          ></i>
        </a>
      </li>
    </ul>
    <ul class="nav-right">
      <li class="mr-5 mt-2" *ngIf="user.user">
        <p>Hello..! {{ user?.user?.first_name }} {{ user?.user?.last_name }}</p>
      </li>
      <li>
        <a
          nz-popconfirm
          nzPopconfirmTitle="Are you sure logout?"
          nzPopconfirmPlacement="bottom"
          (nzOnConfirm)="confirm()"
          (nzOnCancel)="cancel()"
        >
          <i nz-icon nzType="logout" theme="outline"></i>
        </a>
      </li>
      <!-- <li>
                <a (click)="quickViewToggle()">
                    <i nz-icon nzType="appstore" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <ng-container *nzDrawerContent>
                        <app-quick-view></app-quick-view>
                    </ng-container>
                </nz-drawer>
            </li> -->
    </ul>
  </div>
</div>
