import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import { FullLayout_ROUTES } from "./shared/routes/full-layout.routes";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import { EmployeesComponent } from "./employees/employees.component";
import { ShowInvoiceComponent } from "./pages/invoices/showinvoice/showinvoice.component";
import { ModalLayoutComponent } from "./layouts/modal-layout/modal-layout.component";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard/home",
    pathMatch: "full",
  },
  {
    path: "modal",
    component: ModalLayoutComponent,
    children: [
      {
        path: "showinvoice",
        component: ShowInvoiceComponent,
        data: { noNav: true, noTitle: true },
      },
    ],
  },
  {
    path: "",
    component: CommonLayoutComponent,
    children: CommonLayout_ROUTES,
  },
  {
    path: "",
    component: FullLayoutComponent,
    children: FullLayout_ROUTES,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
