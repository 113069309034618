import { SideNavInterface } from "../../interfaces/side-nav.type";
export const ROUTES: SideNavInterface[] = [
  {
    path: "",
    title: "Dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    submenu: [],
  },
  {
    path: "pages/student",
    title: "Students",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "team",
    submenu: [],
  },

  {
    path: "invoices",
    title: "Invoices",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "snippets",
    submenu: [],
  },
  {
    path: "employees",
    title: "Employees",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "idcard",
    submenu: [],
  },

  {
    path: "services",
    title: "Services",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "tag",
    submenu: [],
  },
  {
    path: "trainingsessions",
    title: "Training Sessions",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "car",
    submenu: [],
  },
  {
    path: "pages/operations",
    title: "Operations",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "project",
    submenu: [],
  },
  {
    path: "pages/trainers",
    title: "Trainers",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "car",
    submenu: [],
  },
  // {
  //   path: "pages/transactions",
  //   title: "Transactions",
  //   iconType: "nzIcon",
  //   iconTheme: "outline",
  //   icon: "book",
  //   submenu: [],
  // },
  {
    path: "pages/reports",
    title: "Reports",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "ordered-list",
    submenu: [],
  },
  {
    path: "pages/tax",
    title: "VAT",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "table",
    submenu: [],
  },
  // {
  //   path: 'preferences',
  //   title: 'Preferences',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'tag',
  //   submenu: []
  // },

  // {
  //     path: '',
  //     title: 'Multi Level Menu',
  //     iconType: 'nzIcon',
  //     iconTheme: 'outline',
  //     icon: 'appstore',
  //     submenu: [
  //         {
  //             path: '',
  //             title: 'Level 1',
  //             iconType: '',
  //             icon: '',
  //             iconTheme: '',
  //             submenu: [
  //                 {
  //                     path: '',
  //                     title: 'Level 2',
  //                     iconType: 'nzIcon',
  //                     iconTheme: 'outline',
  //                     icon: '',
  //                     submenu: []
  //                 }
  //             ]
  //         }
  //     ]
  // }
];
