import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';

@Component({
  selector: 'dashboardchart',
  templateUrl: './dashboardchart.component.html',
  styleUrls: ['./dashboardchart.component.css']

})

export class DashboardchartComponent implements OnInit {
  @Input() stats: any ;
  // recives data From parents
  themeColors = this.colorConfig.get().colors;

  blue = this.themeColors.blue;
  blueLight = this.themeColors.blueLight;
  cyan = this.themeColors.cyan;
  cyanLight = this.themeColors.cyanLight;
  gold = this.themeColors.gold;
  purple = this.themeColors.purple;
  purpleLight = this.themeColors.purpleLight;
  red = this.themeColors.red;

  lastmonthdata=[];
  invoiceslastmonth=[];
  regsslastmonth=[];
  labeldates=[];
  revenueChartFormat: any;
  currentrevenueChartLabelsIdx: number;
  revenueChartLabels: any;
  revenueChartOptions: { maintainAspectRatio: boolean; responsive: boolean; hover: { mode: string; intersect: boolean; }; tooltips: { mode: string; }; scales: { xAxes: { gridLines: { display: boolean; }[]; ticks: { display: boolean; fontColor: any; fontSize: number; padding: number; }; }[]; yAxes: { gridLines: { drawBorder: boolean; drawTicks: boolean; borderDash: number[]; zeroLineWidth: number; zeroLineBorderDash: number[]; }; ticks: { display: boolean; max?: number; stepSize: number; fontColor: any; fontSize: number; padding: number; }; }[]; }; };
  revenueChartColors: { backgroundColor: any; borderColor: any; pointBackgroundColor: any; pointBorderColor: any; pointHoverBackgroundColor: any; pointHoverBorderColor: any; }[];
  revenueChartType: string;
  revenueChartData: ({ data: any[]; label: string; backgroundColor?: undefined; borderColor?: undefined;fill?:any } | {
    data: any[];
    //data:[3, 6, 4, 5, 4, 5, 8, 6, 5, 5, 0],
    label: string; backgroundColor: any; borderColor: any;
  })[];
  invoicescountlastmonth: any;
  thismonthchartdata: any;

  constructor(private colorConfig:ThemeConstantService, private dashboardService:DashboardService ) { }

  ngOnInit(): void {
     this.lastmonthdata=this.stats?.data?.last_month_chart_data;
     this.thismonthchartdata=this.stats?.data?.this_month_chart_data;
     console.log(this.lastmonthdata)

    //  this.invoicescountlastmonth=this.stats?.data?.this_month_chart_data
    //  .map(item=>{
    //   return ({
    //     x:item.date,
    //     y:item.invoices
    //   })
    // });
    //  console.log("test",this.invoiceslastmonth);
    //  this.regsslastmonth=this.stats?.data?.this_month_chart_data.map(item=>{
    //     return ({X:item['registrations'],y:item['invoices']})
    //  })




     this.labeldates=this.stats?.data?.this_month_chart_data.map(item=>{
       return item['date']
     })
     console.log("label dates",this.labeldates);
     console.log("test",this.invoiceslastmonth);
     console.log(this.regsslastmonth);
     this.createchart();

  }


  createchart(){
    this.revenueChartFormat = 'revenueMonth';

     this.revenueChartData = [

      {
        data:this.thismonthchartdata.map(item=>{
          return item.invoices
        }),
        backgroundColor: this.themeColors.transparent,
        borderColor: this.red,
        label:'Invoices'
      },
      {
        data:this.thismonthchartdata.map(item=>{
          return item.registrations
        }),
        //data:[3, 6, 4, 5, 4, 5, 8, 6, 5, 5, 0],
        label:'Registrations',
        backgroundColor: this.themeColors.transparent,
        borderColor: this.gold,      },



  ];
    this.currentrevenueChartLabelsIdx = 1;
    this.revenueChartLabels=["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th","12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21th", "22th", "23th", "24th", "25th", "26th","27th", "28th", "29th", "30th", "31st",];
    this.revenueChartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        hover: {
            mode: 'nearest',
            intersect: true
        },
        tooltips: {
            mode: 'index'
        },
        scales: {
            xAxes: [{

                gridLines: [{
                    display: true,
                }],
                ticks: {
                    display: true,
                    fontColor: this.themeColors.grayLight,
                    fontSize: 13,
                    padding: 10
                }
            }],
            yAxes: [{
                gridLines: {
                    drawBorder: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                ticks: {
                    display: true,
                    stepSize:null,
                    fontColor: this.themeColors.grayLight,
                    fontSize: 13,
                    padding: 10
                }
            }],
        }
    };
    //  this.revenueChartColors = [
    //     {
    //         backgroundColor: this.themeColors.transparent,
    //         borderColor: this.cyan,
    //         pointBackgroundColor: this.cyan,
    //         pointBorderColor: this.themeColors.white,
    //         pointHoverBackgroundColor: this.red,
    //         pointHoverBorderColor: this.cyanLight
    //     }
    // ];
    this.revenueChartType = 'line';
  }


   ngAfterViewInit(): void {

    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

   }


}
