import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError, expand, map, reduce } from "rxjs/operators";
import { environment } from "src/environments/environment";

export interface TrainingSessionResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
}

@Injectable({
  providedIn: "root",
})
export class TrainigsessionsService {
  constructor(private http: HttpClient) {}

  public getTrainigSessionsTrainer(id: number, params: any): Observable<any[]> {
    let httpParams = new HttpParams().set("trainer_id", id.toString());

    if (params.created_at__gte) {
      httpParams = httpParams.set("created_at__gte", params.created_at__gte);
    }
    if (params.created_at__lte) {
      httpParams = httpParams.set("created_at__lte", params.created_at__lte);
    }

    return this.http
      .get<any>(environment.baseurl + "/rest/sessions", { params: httpParams })
      .pipe(
        expand((apiResponse) =>
          apiResponse["next"] ? this.http.get<any>(apiResponse["next"]) : EMPTY
        ),
        map((apiResponse) => apiResponse["results"]),
        reduce((accData, data) => accData.concat(data), []),
        catchError(this.handleError)
      );
  }

  public getTrainigSessionsStudent(id: number, params: any): Observable<any[]> {
    let httpParams = new HttpParams().set("student_id", id.toString());

    if (params.created_at__gte) {
      httpParams = httpParams.set("created_at__gte", params.created_at__gte);
    }
    if (params.created_at__lte) {
      httpParams = httpParams.set("created_at__lte", params.created_at__lte);
    }

    return this.http
      .get<any>(environment.baseurl + "/rest/sessions", { params: httpParams })
      .pipe(
        expand((apiResponse) =>
          apiResponse["next"] ? this.http.get<any>(apiResponse["next"]) : EMPTY
        ),
        map((apiResponse) => apiResponse["results"]),
        reduce((accData, data) => accData.concat(data), []),
        catchError(this.handleError)
      );
  }

  private handleError(error: any): Observable<never> {
    console.error("An error occurred:", error); // log to console instead
    return throwError("Something bad happened; please try again later.");
  }

  public getTrainigSessions(): Observable<any> {
    //const params = new HttpParams().set('student',id);
    return this.http.get(environment.baseurl + "rest/sessions").pipe(
      // we recursively call the GET requests until there is no 'next' url
      expand((apiResponse) =>
        apiResponse["next"] ? this.http.get(apiResponse["next"]) : EMPTY
      ),
      // we map the api response to the data we actually want to return
      map((apiResponse) => apiResponse["results"]),
      // we reduce the data of all GET requests to a single array
      reduce((accData, data) => accData.concat(data), [])
    );
  }

  public getTrainigSessionsbydate(from, to): Observable<any> {
    //const params = new HttpParams().set('student',id);
    return this.http.get(environment.baseurl + "rest/sessions").pipe(
      // we recursively call the GET requests until there is no 'next' url
      expand((apiResponse) =>
        apiResponse["next"] ? this.http.get(apiResponse["next"]) : EMPTY
      ),
      // we map the api response to the data we actually want to return
      map((apiResponse) => apiResponse["results"]),
      // we reduce the data of all GET requests to a single array
      reduce((accData, data) => accData.concat(data), [])
    );
  }

  getSessionsCount(id) {
    const params = new HttpParams().set("student", id);
    return this.http.get(environment.baseurl + "rest/sessions" + "?", {
      params: params,
    });
  }

  filtersessionsbydate(date): Observable<TrainingSessionResponse> {
    return this.http
      .get<TrainingSessionResponse>(`${environment.baseurl}/rest/sessions/`, {
        params: date,
      })
      .pipe(
        expand((apiResponse) =>
          apiResponse.next
            ? this.http.get<TrainingSessionResponse>(apiResponse.next)
            : EMPTY
        ),
        reduce(
          (acc, response) => {
            return {
              count: response.count,
              next: response.next,
              previous: response.previous,
              results: acc.results.concat(response.results),
            };
          },
          {
            count: 0,
            next: null,
            previous: null,
            results: [],
          } as TrainingSessionResponse
        )
      );
  }
}
