import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NZ_I18N, en_US } from "ng-zorro-antd/i18n";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";

import {
  registerLocaleData,
  PathLocationStrategy,
  LocationStrategy,
  DatePipe,
} from "@angular/common";
import en from "@angular/common/locales/en";

import { AppRoutingModule } from "./app-routing.module";
import { TemplateModule } from "./shared/template/template.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";

import { NgChartjsModule } from "ng-chartjs";
import { ThemeConstantService } from "./shared/services/theme-constant.service";
import { AuthenticationService } from "./shared/services/authentication.service";
import { ServicesComponent } from "./pages/services/services.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "./shared/interceptor/token.interceptor";
import { ProfileComponent } from "./pages/profile/profile.component";
import { NzAlertModule } from "ng-zorro-antd/alert";
//import { NgSelectModule } from '@ng-select/ng-select/lib/ng-select.module';
import { NgSelectModule } from "@ng-select/ng-select";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { FilterPipe } from "./shared/pipes/filterPipe.pipe";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { SecureInterceptor } from "./shared/interceptor/ensure-https-interceptor";
import { FormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { ModalLayoutComponent } from "./layouts/modal-layout/modal-layout.component";
import { SessionDetailsModalComponent } from "./pages/session-details-modal/session-details-modal.component";

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent,
    ModalLayoutComponent,
    SessionDetailsModalComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
    DataTablesModule,
    NzAlertModule,
    NgSelectModule,
    NzSkeletonModule,
    NzPopconfirmModule,
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    ThemeConstantService,
    AuthenticationService,
    NzNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecureInterceptor,
      multi: true,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
