import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DataTableDirective } from "angular-datatables";
import * as moment from "moment";
import { Subject } from "rxjs";
import { TrainigsessionsService } from "src/app/shared/services/trainigsessions.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { SessionDetailsModalComponent } from "../session-details-modal/session-details-modal.component";

@Component({
  selector: "trainingsessions",
  templateUrl: "./trainingsessions.component.html",
  styleUrls: ["./trainingsessions.component.css"],
})
export class TrainingsessionsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  loading: boolean = false;
  showFilter: boolean = false;

  sessions: any[] = [];
  fromDate: string;
  toDate: string;

  // Pagination parameters

  filterForm = new FormGroup({
    invoice_date__gte: new FormControl(""),
    invoice_date__lte: new FormControl(""),
    invoiced_to__contact__phone_number__icontains: new FormControl(""),
    invoiced_to__traffic_file_number__icontains: new FormControl(""),
    invoiced_to__emirates_id__icontains: new FormControl(""),
    trainer: new FormControl(""),
    application_type: new FormControl(""),
  });

  constructor(
    private trainingsessionsService: TrainigsessionsService,
    private datePipe: DatePipe,
    private modal: NzModalService
  ) {}

  openItemDetails(item: any): void {
    console.log();

    this.modal.create({
      nzTitle: "Session Details",
      nzContent: SessionDetailsModalComponent,
      nzComponentParams: {
        session: item, // Pass the item details to the modal
      },
      nzFooter: null, // Remove footer if you don't want any actions
    });
  }

  ngOnInit() {
    this.initializeDataTable();
    this.getCurrentMonthSessions();
  }

  initializeDataTable() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      order: [[0, "desc"]],
    };
  }

  getSessionHours(startTime: string, endTime: string): string {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffInMs = end.getTime() - start.getTime();
    const diffInHours = diffInMs / (1000 * 60 * 60);
    return diffInHours.toFixed(2); // Format to 2 decimal places
  }

  getCurrentMonthSessions() {
    this.loading = true;
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD HH:mm");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD HH:mm");
    this.fromDate = moment().startOf("month").format("YYYY-MM-DD");
    this.toDate = moment().endOf("month").format("YYYY-MM-DD");

    let params = {
      created_at__gte: startOfMonth,
      created_at__lte: endOfMonth,
      trainer: this.filterForm.get("trainer").value,
      application_type: this.filterForm.get("application_type").value,
    };

    this.trainingsessionsService.filtersessionsbydate(params).subscribe(
      (res) => {
        this.sessions = res.results;
        this.dtTrigger.next();
        this.loading = false;
      },
      (error) => {
        console.error("Error fetching sessions:", error);
        this.loading = false;
      }
    );
  }

  refreshSessions() {
    this.loading = true;

    let params = {
      created_at__gte: moment(this.fromDate).toISOString(),
      created_at__lte: moment(this.toDate).toISOString(),
      trainer: this.filterForm.get("trainer").value,
      application_type: this.filterForm.get("application_type").value,
    };

    this.trainingsessionsService.filtersessionsbydate(params).subscribe(
      (res) => {
        this.sessions = res.results;
        this.refreshTable();
        this.loading = false;
      },
      (error) => {
        console.error("Error refreshing sessions:", error);
        this.loading = false;
      }
    );
  }

  refreshTable() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  closeDrawer() {
    this.showFilter = false;
  }
}
