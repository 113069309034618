// session-details-modal.component.ts
import { Component, Input } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-session-details-modal",
  templateUrl: "./session-details-modal.component.html",
})
export class SessionDetailsModalComponent {
  @Input() session: any;

  getStudentName(): string {
    if (this.session.student) {
      return `${this.session.student.contact.first_name} ${this.session.student.contact.last_name}`;
    } else if (this.session.contact) {
      return `${this.session.contact.first_name} ${this.session.contact.last_name}`;
    }
    return "N/A";
  }

  getStudentPhoneNumber(): string {
    if (this.session.student) {
      return this.session.student.contact.phone_number;
    } else if (this.session.contact) {
      return this.session.contact.phone_number;
    }
    return "N/A";
  }

  getTrainerFullName(): string {
    return `${this.session.trainer.contact.first_name} ${this.session.trainer.contact.last_name}`;
  }

  calculateTotalHours(): number {
    const startTime = moment(this.session.start_time);
    const endTime = moment(this.session.end_time);
    return moment.duration(endTime.diff(startTime)).asHours();
  }

  calculateAmount(): number {
    const ratePerHour = this.session.application_type === 2 ? 40 : 50;
    return this.calculateTotalHours() * ratePerHour;
  }

  getPaymentType(): string {
    switch (this.session.payment_type) {
      case 0:
        return "Bill";
      case 1:
        return "Cash";
      case 2:
        return "Credit";
      default:
        return "Unknown";
    }
  }
}
