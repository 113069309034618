import { Component, OnInit } from "@angular/core";
import { NgxPrinterService } from "ngx-printer";
import { GlobalService } from "src/app/shared/services/global.service";
import { InvoiceService } from "src/app/shared/services/invoice.service";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "showinvoice",
  templateUrl: "./showinvoice.component.html",
  styleUrls: ["./showinvoice.component.css"],
})
export class ShowInvoiceComponent implements OnInit {
  itemData = [];
  invoice: any;
  invoiceId: number;
  globals: any;
  loading: boolean = true;

  constructor(
    private printService: NgxPrinterService,
    private _globals: GlobalService,
    private _invoiceservice: InvoiceService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._globals.getGlobals().subscribe((res) => {
      this.globals = res;
    });

    // Retrieve the invoiceId from URL parameters
    this.route.queryParams.subscribe((params) => {
      this.invoiceId = params["invoiceId"];
      if (this.invoiceId) {
        this.fetchInvoice(this.invoiceId);
      } else {
        this.location.back(); // Go back if no invoiceId is found
      }
    });
  }

  fetchInvoice(invoiceId: number): void {
    this._invoiceservice.getInvoiceById(invoiceId).subscribe(
      (res) => {
        this.invoice = res;
        this.itemData = this.invoice.particulars;
        this.loading = false;
      },
      (error) => {
        console.error("Error fetching invoice:", error);
        this.location.back(); // Go back if there is an error
      }
    );
  }

  getTotal(): string {
    const subTotal = this.itemData.reduce(
      (prev, next) => prev + (next.cost + next.profit) * next.quantity,
      0
    );
    return subTotal.toFixed(2);
  }

  printInvoice() {
    this.printService.printDiv("invoice");
  }

  isSuperadmin() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user.user.is_superuser;
  }

  confirm() {
    console.log("clicked confirm");
    this.loading = true;
    if (this.isSuperadmin()) {
      this._invoiceservice.deleteInvoice(this.invoice.id).subscribe(
        (res) => {
          console.log(res);
          this.loading = false;
          this.router.navigate(["/invoices"]).then(() => {
            window.close();
          });
        },
        (error) => {
          this.loading = false;
          console.error("Error deleting invoice:", error);
        }
      );
    } else {
      console.log("you are not authorized");
      this.loading = false;
    }
  }

  cancel() {}

  toTitleCase(str: string): string {
    if (!str) return str;
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
