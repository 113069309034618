import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  url=environment.baseurl+'rest/api/getGlobals'
  constructor(private http:HttpClient) { }

  getGlobals(){
    return this.http.get(this.url);
  }
}
