<!-- Trainer Full Name -->
<h4>Trainer</h4>
<p><strong>Name:</strong> {{ getTrainerFullName() }}</p>

<!-- Student Name and Phone Number -->
<div *ngIf="session.student || session.contact">
  <h4>Student</h4>
  <p><strong>Name:</strong> {{ getStudentName() }}</p>
  <p><strong>Phone Number:</strong> {{ getStudentPhoneNumber() }}</p>
</div>

<!-- Vehicle Plate Number -->
<div *ngIf="session.vehicle">
  <h4>Vehicle</h4>
  <p><strong>Plate Number:</strong> {{ session.vehicle.plate_number }}</p>
</div>

<!-- Session Details -->
<h4>Session Details</h4>
<p>
  <strong>Total Hours:</strong>
  {{ calculateTotalHours() | number : "1.2-2" }} hours
</p>
<p><strong>Amount:</strong> {{ calculateAmount() | number : "1.2-2" }} AED</p>
<p><strong>Payment Type:</strong> {{ getPaymentType() }}</p>

<!-- Notes -->
<div *ngIf="session.notes">
  <h4>Notes</h4>
  <p>{{ session.notes }}</p>
</div>
