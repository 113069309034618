import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAutosizeDirective, NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzOptionComponent, NzSelectModule } from 'ng-zorro-antd/select';
import { TableService } from './services/table.service';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzResizeObserverModule } from 'ng-zorro-antd/cdk/resize-observer';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { DataTablesModule } from 'angular-datatables';
import { InvoiceComponent } from '../pages/invoices/invoice/invoice.component';
import { InvoicesComponent } from '../pages/invoices/invoices.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCardModule } from 'ng-zorro-antd/card';
import { FilterPipe } from './pipes/filter.pipe';
import { DashboardchartComponent } from '../pages/dashboardchart/dashboardchart.component';
import { NgChartjsModule } from 'ng-chartjs';
import { NzSpinComponent, NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { RevenuechartComponent } from '../pages/revenuechart/revenuechart.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { TrainingsessionComponent } from '../pages/trainingsession/trainingsession.component';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NzIconModule,
        NzSpaceModule,
        PerfectScrollbarModule,
        SearchPipe,
        NzAutocompleteModule,
        NzInputModule,
        NzDatePickerModule,
        NzFormModule,
        ReactiveFormsModule,
        NzInputModule,
        NzSelectModule,
        NzDrawerModule,
        NzResizeObserverModule,
        NzResizableModule,
        DataTablesModule,
        NzDropDownModule,
        DashboardchartComponent,
        NgChartjsModule,
        NzSpinComponent,
        NzPopconfirmModule,
        RevenuechartComponent,
        NzModalModule,
        NzCardModule,

        NzButtonModule
    ],
    imports: [
        RouterModule,
        CommonModule,
        NzIconModule,
        NzToolTipModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        NzInputModule,
        NzSelectModule,
        NzDrawerModule,
        NzResizableModule,
        NzDropDownModule,
        DataTablesModule,
        NgChartjsModule,
        NzSpinModule,
        NzPopconfirmModule,
        NzModalModule,
        NzCardModule,
        NzButtonModule


    ],
    declarations: [
        SearchPipe,
        DashboardchartComponent,
        RevenuechartComponent,




    ],
    providers: [
        ThemeConstantService,
        TableService,

    ]
})

export class SharedModule { }
