import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { catchError, expand, map, reduce } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  invoicecount: any;

  constructor(private http: HttpClient) {}

  headers = new HttpHeaders()
    // .set('content-type', 'application/json')
    .set("Authorization", "Token " + localStorage.getItem("token"));
  createInvoice(invoice) {
    return this.http.post(environment.baseurl + "rest/invoices", invoice);
  }

  getInvoiceById(invoiceId: number): Observable<any> {
    return this.http.get(`${environment.baseurl}/rest/invoices/${invoiceId}`);
  }

  getOrganizations(): Observable<any> {
    return this.http.get<any>(`${environment.baseurl}/rest/organizations/`);
  }

  getInvoice() {
    return this.http.get(environment.baseurl + "rest/invoices").pipe(
      // we recursively call the GET requests until there is no 'next' url
      expand((apiResponse) =>
        apiResponse["next"] ? this.http.get(apiResponse["next"]) : EMPTY
      ),

      map((apiResponse) => apiResponse["results"]),
      // we reduce the data of all GET requests to a single array
      reduce((accData, data) => accData.concat(data), [])
    );
  }

  filterInvoices(formdata) {
    const params = new HttpParams({ fromObject: formdata });
    return this.http
      .get(environment.baseurl + "rest/invoices", { params: formdata })
      .pipe(
        // we recursively call the GET requests until there is no 'next' url
        expand((apiResponse) =>
          apiResponse["next"] ? this.http.get(apiResponse["next"]) : EMPTY
        ),
        // we map the api response to the data we actually want to return
        map((apiResponse) => apiResponse["results"]),
        // we reduce the data of all GET requests to a single array
        reduce((accData, data) => accData.concat(data), [])
      );
  }

  getInvoicesbyid(id) {
    const params = new HttpParams().set("invoiced_to__id", id);
    console.log(
      "%cinvoice.service.ts line:48 params",
      "color: #007acc;",
      params
    );
    return this.http
      .get(environment.baseurl + "rest/invoices" + "?", { params: params })
      .pipe(
        // we recursively call the GET requests until there is no 'next' url
        expand((apiResponse) =>
          apiResponse["next"] ? this.http.get(apiResponse["next"]) : EMPTY
        ),
        // we map the api response to the data we actually want to return
        map((apiResponse) => apiResponse["results"]),
        // we reduce the data of all GET requests to a single array
        reduce((accData, data) => accData.concat(data), [])
      );
  }

  getInvoiceCount(id) {
    const params = new HttpParams().set("invoiced_to__id", id);
    return this.http.get(environment.baseurl + "rest/invoices" + "?", {
      params: params,
    });
  }

  // getInitialInvoices(daterange) {
  //   const params = new HttpParams();
  //   return this.http
  //     .get(environment.baseurl + "rest/invoices", { params: daterange })
  //     .pipe(
  //       // we recursively call the GET requests until there is no 'next' url
  //       expand((apiResponse) =>
  //         apiResponse["next"] ? this.http.get(apiResponse["next"]) : EMPTY
  //       ),
  //       // we map the api response to the data we actually want to return
  //       map((apiResponse) => apiResponse["results"]),
  //       // we reduce the data of all GET requests to a single array
  //       reduce((accData, data) => accData.concat(data), [])
  //     );
  // }

  deleteInvoice(id) {
    return this.http.delete(environment.baseurl + "rest/invoices/" + id);
  }
  getInvoicedbyemployee(id) {
    const params = new HttpParams().set("invoiced_by__id", id);
    return this.http
      .get(environment.baseurl + "rest/invoices", { params: params })
      .pipe(
        // we recursively call the GET requests until there is no 'next' url
        expand((apiResponse) =>
          apiResponse["next"] ? this.http.get(apiResponse["next"]) : EMPTY
        ),
        // we map the api response to the data we actually want to return
        map((apiResponse) => apiResponse["results"]),
        // we reduce the data of all GET requests to a single array
        reduce((accData, data) => accData.concat(data), [])
      );
  }

  getInvoices(
    start: number,
    length: number,
    filter: any = {},
    search: string = ""
  ) {
    let queryParams = `start=${start}&length=${length}&ordering=-id&search=${search}`;
    Object.keys(filter).forEach((key) => {
      queryParams += `&${key}=${filter[key]}`;
    });
    return this.http
      .get(
        `${environment.baseurl}rest/invoices?format=datatables&draw=1&${queryParams}`
      )
      .pipe(
        map((apiResponse: any) => ({
          data: apiResponse["data"],
          recordsTotal: apiResponse["recordsTotal"],
          recordsFiltered: apiResponse["recordsFiltered"],
        })),
        catchError((error) => {
          console.error("Error fetching invoices:", error);
          return EMPTY;
        })
      );
  }
}
