<div>
  <nz-card>
    <div class="page-header" style="margin-left: 20px; margin-right: 20px">
      <div class="row align-items-md-center">
        <div class="col-md-6">
          <div class="d-flex align-items-center">
            <nz-avatar
              nzIcon="user"
              [nzSize]="64"
              [nzShape]="'circle'"
              class="ant-avatar-cyan"
            ></nz-avatar>
            <div class="ml-3">
              <h4 class="mb-0">{{ title }}</h4>
              <p class="mb-0">Total Records: {{ totalRecords }}</p>
            </div>
          </div>
        </div>

        <div class="col-md-6" *ngIf="role !== 2">
          <div class="text-md-right m-v-10">
            <button
              nz-button
              nzType="primary"
              class="mr-3"
              (click)="addProfile()"
            >
              <i nz-icon nzType="plus"></i>Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-11 mx-auto">
        <div class="row mb-3">
          <div class="col-md-12 d-flex align-items-center justify-content-end">
            <label for="itemsPerPage" class="mr-2">Items per page:</label>
            <nz-select
              id="itemsPerPage"
              [(ngModel)]="pageLength"
              (ngModelChange)="onPageLengthChange($event)"
            >
              <nz-option
                *ngFor="let size of [10, 20, 30, 40, 50]"
                [nzValue]="size"
                [nzLabel]="size"
              ></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <form [formGroup]="searchForm">
              <nz-input-group [nzSuffix]="suffixIconSearch">
                <input nz-input placeholder="Search" formControlName="search" />
              </nz-input-group>
              <ng-template #suffixIconSearch>
                <span nz-icon nzType="search"></span>
              </ng-template>
            </form>
          </div>
        </div>
        <!-- Card View -->
        <div class="row" *ngIf="view == 'cardView'">
          <div class="col-md-3" *ngFor="let employee of employees">
            <nz-card>
              <div class="m-t-20 text-center">
                <nz-avatar
                  class="shadow"
                  [nzSize]="100"
                  nzIcon="user"
                  [nzSrc]=""
                ></nz-avatar>
                <h4 class="m-t-30 m-b-0">{{ employee.contact?.first_name }}</h4>
                <h4 class="m-t-0">{{ employee.contact?.last_name }}</h4>
                <p>{{ employee?.unique_id }}</p>
              </div>
              <div class="text-center m-t-15">
                <form>
                  <button
                    class="m-r-10"
                    nz-button
                    nzType="default"
                    nzShape="circle"
                  >
                    <i nz-icon nzType="mail"></i>
                  </button>
                </form>
                <button
                  class="m-r-10"
                  nz-button
                  nzType="default"
                  nzShape="circle"
                >
                  <i nz-icon nzType="phone"></i>
                </button>
                <button
                  class="m-r-10"
                  nz-button
                  nzType="default"
                  nzShape="circle"
                >
                  <i nz-icon nzType="whats-app"></i>
                </button>
              </div>
              <div class="text-center m-t-30">
                <a
                  [routerLink]="['/pages/view-profile']"
                  [state]="{ id: employee?.id }"
                  nz-button
                  nzType="primary"
                  nzGhost
                >
                  <i nz-icon nzType="user" theme="outline"></i>
                  <span class="m-l-5">Profile</span>
                </a>
              </div>
            </nz-card>
          </div>
        </div>
        <!-- List View -->
        <div class="row" *ngIf="view == 'listView'">
          <div class="col-md-12 ant-table-wrapper ng-star-inserted">
            <div class="hscroll">
              <div
                *ngIf="loading"
                class="d-flex justify-content-center align-items-center"
              >
                <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
              </div>
              <table
                *ngIf="!loading"
                #listViewTable
                nz-table
                datatable
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger"
                class="table ant-table no-border-last"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Created On</th>
                    <th>TCN</th>
                    <th>Emirates Id</th>
                    <th>Phone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of employees">
                    <td>
                      <div class="media align-items-center">
                        <nz-avatar
                          nzIcon="user"
                          [nzSrc]="
                            item?.profile_image | transformProfileImageUrl
                          "
                        ></nz-avatar>
                        <div class="m-l-10">
                          <span class="text-gray">{{
                            item?.contact?.first_name | titlecase
                          }}</span
                          ><br />
                          <span class="text-gray">{{
                            item?.contact?.last_name | titlecase
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span class="text-gray">{{
                        item?.contact?.created_at | date : "dd-MM-YYYY"
                      }}</span>
                    </td>
                    <td>
                      <span class="text-gray">{{
                        item?.traffic_file_number
                      }}</span>
                    </td>
                    <td>
                      <span class="text-gray">{{ item?.emirates_id }}</span>
                    </td>
                    <td>
                      <span class="text-gray">{{
                        item?.contact?.phone_number | phoneNumber : "AE"
                      }}</span>
                    </td>
                    <td class="text-right">
                      <a
                        [routerLink]="['/pages/view-profile']"
                        [state]="{ id: item?.id }"
                        nz-button
                        nzType="primary"
                        nzGhost
                      >
                        <span class="m-l-5">Profile</span>
                        <i nz-icon nzType="right" theme="outline"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Pagination Controls -->
              <div
                class="pagination-controls"
                style="float: right; display: flex; align-items: center"
              >
                <button
                  nz-button
                  (click)="goToFirstPage()"
                  [disabled]="currentPage === 0"
                >
                  First
                </button>
                <button
                  nz-button
                  (click)="onPageChange(currentPage - 1)"
                  [disabled]="currentPage === 0"
                >
                  Previous
                </button>
                <ng-container *ngFor="let page of getPageNumbers()">
                  <button
                    nz-button
                    (click)="goToPage(page)"
                    [nzType]="page === currentPage + 1 ? 'primary' : 'default'"
                  >
                    {{ page }}
                  </button>
                </ng-container>
                <button
                  nz-button
                  (click)="onPageChange(currentPage + 1)"
                  [disabled]="currentPage + 1 >= totalPages"
                >
                  Next
                </button>
                <button
                  nz-button
                  (click)="goToLastPage()"
                  [disabled]="currentPage + 1 >= totalPages"
                >
                  Last
                </button>
                <span style="padding-left: 10px"
                  >Page {{ currentPage + 1 }} of {{ totalPages }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <nz-drawer
        [nzClosable]="true"
        [nzVisible]="showFilter"
        nzPlacement="right"
        nzTitle="Filter Invoices"
        (nzOnClose)="closeDrawer()"
        [nzWidth]="400"
      >
        <ng-container *nzDrawerContent class="p-20">
          <form
            [formGroup]="filterForm"
            (ngSubmit)="onSubmitfilterForm()"
            nz-form
            nzLayout="vertical"
            *ngIf="showFilter"
          >
            <div nz-row>
              <div nz-col-12 class="mr-4">
                <nz-form-item>
                  <nz-form-label nzFor="service Name">First Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please input this field!">
                    <input nz-input formControlName="first_name" type="text" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col-12 class="mr-4">
                <nz-form-item>
                  <nz-form-label>Phone Number</nz-form-label>
                  <nz-form-control nzErrorTip="Please input this field!">
                    <input
                      nz-input
                      formControlName="phone_number"
                      type="text"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col-12 class="mr-4">
                <nz-form-item>
                  <nz-form-label>Emirates ID</nz-form-label>
                  <nz-form-control nzErrorTip="Please input this field!">
                    <input nz-input formControlName="emirates_id" type="text" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col-12 class="mr-4">
                <nz-form-item>
                  <nz-form-label>TCN Number</nz-form-label>
                  <nz-form-control nzErrorTip="Please input this field!">
                    <input
                      nz-input
                      formControlName="traffic_file_number"
                      type="text"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div class="mr-3">
                <nz-form-item>
                  <button type="submit" nz-button nzType="primary">
                    Filter
                  </button>
                </nz-form-item>
              </div>
              <div class="mr-3">
                <nz-form-item>
                  <button
                    type="button"
                    nz-button
                    nzType="primary"
                    *ngIf="isFiltered"
                    (click)="resetFilter()"
                  >
                    Reset
                  </button>
                </nz-form-item>
              </div>
              <nz-form-item>
                <button (click)="closeDrawer()" nz-button nzType="default">
                  Cancel
                </button>
              </nz-form-item>
            </div>
          </form>
        </ng-container>
      </nz-drawer>
    </div>
  </nz-card>
</div>
