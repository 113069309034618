import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { FormGroup, FormControl } from "@angular/forms";
import { TableService } from "../../shared/services/table.service";
import { ProductsService } from "src/app/shared/services/products.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzMessageService } from "ng-zorro-antd/message";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { Utilities } from "src/assets/data/utilities";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"],
})
export class ServicesComponent implements OnInit {
  editMode: boolean;
  selecteditem: any;
  currentindex: any;
  currentid: any;
  loading: boolean;
  tittle;
  utils;

  //angular data tables
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  constructor(
    private tableSvc: TableService,
    private Fb: FormBuilder,
    private service: ProductsService,
    private notification: NzNotificationService
  ) {
    this.utils = Utilities;
    //this.displayData = this.ordersList;
  }

  serviceForm = new FormGroup({
    title: new FormControl(""),
    service_code: new FormControl(""),
    price: new FormGroup({
      title: new FormControl(""),
      cost: new FormControl("0"),
      profit: new FormControl("0"),
      tax: new FormControl("0"),
    }),
  });

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 20,
      order: [3, "asc"],
    };
    this.getProducts();
    this.utils.isSuperadmin();
  }

  allChecked: boolean = false;
  indeterminate: boolean = false;
  displayData = [];
  searchInput: string;
  services = [];
  //  Drawer Controls
  visible = false;
  placement: NzDrawerPlacement = "right";
  size: "large";

  orderColumn = [
    {
      title: "ID",
    },
    {
      title: "Service Code",
    },
    {
      title: "Service",
    },
    {
      title: "Created On",
    },
    {
      title: "Price",
    },
    // {
    //   title: 'Status',

    // },
    {
      title: "",
    },
  ];

  search() {
    //const data = this.ordersList
    const data = this.services;
    this.displayData = this.tableSvc.search(this.searchInput, data);
  }

  closeDrawer() {
    this.visible = false;
  }

  openDarwer() {
    this.visible = true;
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    this.serviceForm.patchValue({
      price: {
        title: this.serviceForm.get("title").value,
      },
    });
    console.warn(this.serviceForm.value);
    this.closeDrawer();
    this.service.addProducts(this.serviceForm.value).subscribe((res) => {
      console.log(res);
      this.notification.success("Success", "Saved Succcessfully");
      this.refreshTable();
    });
  }
  addProduct() {
    this.editMode = false;
    this.tittle = "Add Service";
    this.openDarwer();
  }

  getProducts() {
    this.loading = true;
    this.service.getProducts().subscribe(
      (res) => {
        this.services = res["results"];
        this.displayData = this.services;
        this.dtTrigger.next();
        this.loading = false;
        console.log(res["results"]);
      },
      (error) => {}
    );
  }
  deleteItem() {
    this.notification.warning("Heads Up", "Are Sure To Delete?");
  }

  onClickeditItem(item, i) {
    this.editMode = true;
    this.tittle = "Edit Service";
    console.log(item, i);
    this.openDarwer();
    this.serviceForm.patchValue(item);
    this.selecteditem = item;
    this.currentid = i;
  }

  editItem() {
    this.loading = true;
    let id = this.selecteditem.id;
    console.log(this.selecteditem.id);
    this.service.editProducts(id, this.serviceForm.value).subscribe(
      (res) => {
        this.closeDrawer();
        this.refreshTable();
        this.loading = false;
        this.notification.success("Success", "Action Compleeted Successfully");
      },
      (error) => {
        this.notification.error("Error", error);
      }
    );
  }

  refreshTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      //this.dtTrigger.next();
    });
    this.getProducts();
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls)
          dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }
  confirm(id) {
    this.service.deleteProduct(id).subscribe((res) => {
      console.log(res);
      if (res["status"] == 200) {
        this.notification.success("Success", "Service deleted Successfully");
      }
    });
    this.refreshTable();
  }
}
