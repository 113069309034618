import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { errorhandler } from './errorhandler';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  url= environment.baseurl+'api/getStats'
  constructor(private http:HttpClient,) {

   }

  getStats(){

    return this.http.get(this.url);
  }

  getstatsbyDate(from,to){
    let dates={
      start_date:from,
      end_date:to
    }
    let params = new HttpParams().append('param', 'value');
    return this.http.get(this.url,{params:dates});
  }

}
