import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { AuthenticationService } from "../../services/authentication.service";
import { ThemeConstantService } from "../../services/theme-constant.service";
import { GlobalService } from "../../services/global.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent {
  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  globals: any;
  user;

  constructor(
    private themeService: ThemeConstantService,
    private Authservice: AuthenticationService,
    private router: Router,
    private notification: NzNotificationService,
    private _globals: GlobalService
  ) {}

  ngOnInit(): void {
    this._globals.getGlobals().subscribe((res) => {
      this.globals = res;
    });

    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.getuser();
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  logout() {
    this.notification.warning("Heads Up", "Are Sure To logout?");
  }

  confirm() {
    this.Authservice.logout();
  }
  cancel() {}
  notificationList = [
    {
      title: "You received a new message",
      time: "8 min",
      icon: "mail",
      color: "ant-avatar-" + "blue",
    },
    {
      title: "New user registered",
      time: "7 hours",
      icon: "user-add",
      color: "ant-avatar-" + "cyan",
    },
    {
      title: "System Alert",
      time: "8 hours",
      icon: "warning",
      color: "ant-avatar-" + "red",
    },
    {
      title: "You have a new update",
      time: "2 days",
      icon: "sync",
      color: "ant-avatar-" + "gold",
    },
  ];

  getuser() {
    let user = JSON.parse(localStorage.getItem("user"));
    // console.log("from heaader",user);
    this.user = user;
  }
}
