import { Pipe, PipeTransform } from "@angular/core";
import {
  parsePhoneNumberFromString,
  AsYouType,
  CountryCode,
} from "libphonenumber-js";

@Pipe({
  name: "phoneNumber",
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string, country: string = "US"): string {
    if (!value) return value;

    try {
      // Cast the country string to the CountryCode type
      const phoneNumber = parsePhoneNumberFromString(
        value,
        country as CountryCode
      );

      // Format the phone number in international format
      return phoneNumber ? phoneNumber.formatInternational() : value;
    } catch (e) {
      // If parsing fails, return the original value
      return value;
    }
  }
}
