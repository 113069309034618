<div [hidden]="loading">
  <div nz-row nzJustify="start" nzGutter="16">
    <!-- From Date Picker -->
    <div nz-col class="col-md-6 col-lg-3">
      <div class="m-b-10 m-r-10">
        <nz-date-picker
          [(ngModel)]="fromDate"
          nzPlaceHolder="From"
        ></nz-date-picker>
      </div>
    </div>

    <!-- To Date Picker -->
    <div nz-col class="col-md-6 col-lg-3">
      <div class="m-b-10 m-r-10">
        <nz-date-picker
          [(ngModel)]="toDate"
          nzPlaceHolder="To"
        ></nz-date-picker>
      </div>
    </div>

    <!-- Search Button -->
    <div nz-col class="col-md-6 col-lg-3">
      <div class="m-b-10 m-l-10 d-flex align-items-right">
        <button nz-button nzBlock nzType="primary" (click)="refreshSessions()">
          <i nz-icon nzType="search" theme="outline"></i>
          <span>Search</span>
        </button>
      </div>
    </div>
  </div>

  <nz-card nzTitle="Training Sessions">
    <div class="ant-table-wrapper ng-star-inserted hscroll">
      <table
        nz-table
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        class="table ant-table"
      >
        <thead>
          <tr>
            <th>Id</th>
            <th>Trainer</th>
            <th>Student</th>
            <th>From</th>
            <th>To</th>
            <th>Hours</th>
            <th>Session Type</th>
            <th>Payment Type</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of sessions" (click)="openItemDetails(item)">
            <td>{{ item.id }}</td>
            <td>
              {{ item.trainer.contact.first_name }}
              {{ item.trainer.contact.last_name }}
            </td>
            <td>
              <ng-container *ngIf="item.student; else noStudent">
                {{ item.student?.contact?.first_name }}
                {{ item.student?.contact?.last_name }}
              </ng-container>
              <ng-template #noStudent>
                {{ item.contact?.first_name }} {{ item.contact?.last_name }}
              </ng-template>
            </td>
            <td>{{ item.start_time | date : "dd-MM-yyyy hh:mm a" }}</td>
            <td>{{ item.end_time | date : "dd-MM-yyyy hh:mm a" }}</td>
            <td>{{ getSessionHours(item.start_time, item.end_time) }}</td>
            <td>
              {{
                item.application_type === 0
                  ? "Automatic"
                  : item.application_type === 1
                  ? "Manual"
                  : "Bike"
              }}
            </td>
            <td>
              {{
                item.application_type === 0
                  ? "Bill"
                  : item.application_type === 1
                  ? "Cash"
                  : "Credit"
              }}
            </td>
            <td>{{ item.notes }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </nz-card>
</div>

<div
  *ngIf="loading"
  class="d-flex flex-column min-vh-100 justify-content-center align-items-center"
>
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>
