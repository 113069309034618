import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { ProfileService } from "../shared/services/profile.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.css"],
})
export class EmployeesComponent implements OnInit {
  @Input() role = 2;
  @Input() title = "Employees";
  employees = [];
  showFilter: boolean;
  view: string = "listView";
  filterForm: FormGroup;
  searchForm: FormGroup;
  isFiltered: boolean;
  totalRecords: number;
  totalPages: number;
  loading: boolean;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  // Pagination parameters
  currentPage: number = 0;
  pageLength: number = 20;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.filterForm = this.fb.group({
      first_name: new FormControl(""),
      phone_number: new FormControl(""),
      traffic_file_number: new FormControl(""),
      emirates_id: new FormControl(""),
    });

    this.searchForm = this.fb.group({
      search: new FormControl(""),
    });

    this.searchForm.get("search")?.valueChanges.subscribe((value) => {
      this.onSearchChange(value);
    });
  }

  ngOnInit(): void {
    this.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      pageLength: this.pageLength,
    };
    localStorage.setItem("selected_role", String(this.role));
    this.getProfiles(this.currentPage, this.pageLength);
  }

  getProfiles(start: number, length: number) {
    this.loading = true;
    const filter = this.isFiltered ? this.getDirtyValues(this.filterForm) : {};
    const search = this.searchForm.get("search")?.value || "";
    this.profileService
      .getProfilesbyRole(this.role, start, length, filter, search)
      .subscribe((res) => {
        this.employees = res.data;
        this.totalRecords = res.recordsFiltered;
        this.totalPages = Math.ceil(this.totalRecords / this.pageLength);
        this.loading = false;
        this.dtTrigger.next();
      });
  }

  addProfile() {
    this.router.navigate(["dashboard/pages/profile"]);
  }

  onSubmitfilterForm() {
    this.isFiltered = true;
    this.currentPage = 0;
    this.getProfiles(this.currentPage, this.pageLength);
  }

  closeDrawer() {
    this.showFilter = false;
  }

  resetFilter() {
    this.filterForm.reset();
    this.isFiltered = false;
    this.currentPage = 0;
    this.refreshTable();
    this.getProfiles(this.currentPage, this.pageLength);
  }

  onClickfilter() {
    this.showFilter = true;
  }

  getDirtyValues(form: FormGroup) {
    let dirtyValues: any = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];
      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  refreshTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear();
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  onPageChange(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.getProfiles(this.currentPage * this.pageLength, this.pageLength);
    }
  }

  onSearchChange(search: string) {
    this.currentPage = 0;
    this.getProfiles(this.currentPage, this.pageLength);
  }

  getPageNumbers(): number[] {
    const maxPages = 5;
    let startPage = Math.max(0, this.currentPage - Math.floor(maxPages / 2));
    const endPage = Math.min(startPage + maxPages - 1, this.totalPages - 1);
    startPage = Math.max(0, endPage - maxPages + 1);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i + 1);
    }
    return pages;
  }

  goToPage(page: number) {
    this.onPageChange(page - 1);
  }

  goToFirstPage() {
    this.onPageChange(0);
  }

  goToLastPage() {
    this.onPageChange(this.totalPages - 1);
  }

  onPageLengthChange(newLength: number) {
    this.pageLength = newLength;
    this.currentPage = 0;
    this.getProfiles(this.currentPage, this.pageLength);
  }
}
