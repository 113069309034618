import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';

@Component({
  selector: 'revenuechart',
  templateUrl: './revenuechart.component.html',
  styleUrls: ['./revenuechart.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class RevenuechartComponent implements OnInit {
  @Input() stats: any ;
  themeColors = this.colorConfig.get().colors;
  blue = this.themeColors.blue;
  blueLight = this.themeColors.blueLight;
  cyan = this.themeColors.cyan;
  cyanLight = this.themeColors.cyanLight;
  gold = this.themeColors.gold;
  purple = this.themeColors.purple;
  purpleLight = this.themeColors.purpleLight;
  red = this.themeColors.red;
  lastmonthdata: any;
  thismonthdata: any;
  lastmonthrevenue: any;
  thismonthrevenue: any;
  revenueChartFormat: string;
  revenueChartData: { data: any; backgroundColor: any; borderColor: any; label: string; }[];
  currentrevenueChartLabelsIdx: number;
  revenueChartLabels: any;
  revenueChartOptions: { maintainAspectRatio: boolean; responsive: boolean; hover: { mode: string; intersect: boolean; }; tooltips: { mode: string; }; scales: { xAxes: { gridLines: { display: boolean; }[]; ticks: { display: boolean; fontColor: any; fontSize: number; padding: number; }; }[]; yAxes: { gridLines: { drawBorder: boolean; drawTicks: boolean; borderDash: number[]; zeroLineWidth: number; zeroLineBorderDash: number[]; }; ticks: { display: boolean; stepSize: any; fontColor: any; fontSize: number; padding: number; }; }[]; }; };
  revenueChartType: string;
  revenueChartColors: { backgroundColor: any; borderColor: any; pointBackgroundColor: any; pointBorderColor: any; }

  constructor(private colorConfig:ThemeConstantService) { }

  ngOnInit(): void {
    console.log("from rvenue", this.stats);
    this.lastmonthrevenue=this.stats?.data?.last_month_chart_data
    .map(item=>{

      return item.total_invoice_amount
    });
    this.thismonthrevenue=this.stats?.data?.this_month_chart_data
    .map(item=>{

      return item.total_invoice_amount

    });
    console.log(this.thismonthrevenue);
    this.createchart();
  }

  createchart(){
    this.revenueChartFormat = 'revenueMonth';

     this.revenueChartData = [
      {
        data:this.lastmonthrevenue,
        backgroundColor: this.themeColors.transparent,
        borderColor: this.red,
        label:'Last Month Revenue'
      },
      {
        data:this.thismonthrevenue,
        //data:[3, 6, 4, 5, 4, 5, 8, 6, 5, 5, 0],
        label:'This Month Revenue',
        backgroundColor: this.themeColors.transparent,
        borderColor: this.gold,      },




  ];
    this.currentrevenueChartLabelsIdx = 1;
    this.revenueChartLabels=["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th","12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21th", "22th", "23th", "24th", "25th", "26th","27th", "28th", "29th", "30th", "31st",];
    this.revenueChartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        hover: {
            mode: 'nearest',
            intersect: true
        },
        tooltips: {
            mode: 'index'
        },

        scales: {
            xAxes: [{

                gridLines: [{
                    display: true,
                }],
                ticks: {
                    display: true,
                    fontColor: this.themeColors.grayLight,
                    fontSize: 13,
                    padding: 10
                }
            }],
            yAxes: [{
                gridLines: {
                    drawBorder: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                ticks: {
                    display: true,
                    stepSize:null,
                    fontColor: this.themeColors.grayLight,
                    fontSize: 13,
                    padding: 10
                }
            }],
        }
    };
    //  this.revenueChartColors = [
    //     {
    //         backgroundColor: this.themeColors.transparent,
    //         borderColor: this.cyan,
    //         pointBackgroundColor: this.cyan,
    //         pointBorderColor: this.themeColors.white,
    //         pointHoverBackgroundColor: this.cyanLight,
    //         pointHoverBorderColor: this.cyanLight
    //     }
    // ];
    this.revenueChartType = 'line';
  }


}
